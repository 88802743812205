#order-search-text-container {
    display:flex;
}

#order-search-text-container input {
    flex: 1;
    margin-right: 5px;
}

#order-search-result-container {
    margin-top:15px;
}

.order-search-result-action-view {
    display: block;
}

#order-search-result-container th .btn-link {
    color: rgb(33, 37, 41);
    font-weight: 700;
}

.order-search-action-title {
    text-transform: uppercase;
    font-size: 1.1rem;
    color: #666666;
    font-weight: 600;
    margin-bottom: 8px;
    display: block;
}

.order-search-action-details, .order-search-action-notes-container, .order-search-action-price-summary-container, .order-search-action-modal label {
    font-size: .9rem;
    color: #606060;
}

.order-search-action-notes-container {
    margin-top: 10px;
}

.order-search-action-price-summary-container  {
    border-bottom: 1px solid #ced4da;
    display: inline-block;
}

.order-search-action-price-container {
    text-align: right;
}

.order-search-add-order-button {
    text-align: center;
}

.order-filter-criteria {
    margin-top: 1rem;

    legend {
        font-size: 1em;
        margin-bottom: 0;
    }
}

.order-search-header {
    display:flex;
    justify-content: space-between;
}

.order-search-primary-content.card {
    background-color: #fafafa;
    margin-bottom: 1.25rem;
}

.order-search-primary-content .card-body {
    padding-bottom:0;
}

#order-search-result-container{
    .form-check-input {
        position: relative;
    }
}

@media only screen and (max-width: 600px) {
    .sort-results-mobile {
        display: flex;
        padding: 1rem 0;

        label {
            margin-right: 1rem;
            width: 40%
        }
    }
}