#inventory-search-result-container {
  margin-top: 15px;
  background-color: #fff;
  padding: 0 10px;
  border-radius: 4px;
  margin-bottom: 4px;
  overflow-x: auto;

  .row {
    width: 100%;
  }

  .inventory-type-color {
    margin-right: .5rem;
    display: inline-block;
  }
}

.table-to-card-content {
  word-break:  break-word;
}

.inventory-search-result-item-used-notification {
  display: inline-block;
  margin-right: 3px;
}

#inventory-search-result-container .table th {
  border-top: none;
}

#inventory-search-results-no-results {
  text-align: center;
  padding: 2rem;
}