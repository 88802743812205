.getting-started-container {
  .getting-started-header {
    font-size: 36pt;
    margin-bottom: 6rem;
  }

  .getting-started-icons {
    display: flex;
    justify-content: space-evenly;
  }

  .getting-started-icon {
    img {
      height: 7rem;
      display:block;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 1rem;
    }

    align-content: center;
    width:33.3%;
    font-size: 20pt;
    text-align: center;
  }

  .getting-started-start-tour {
    margin-top: 4rem;
    text-align:center;
    button {font-size: 36pt;}
  }

  .getting-started-general-footer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 5rem;

    div {width:33.3%; align-content: center;}
  }
}

.getting-started-general {
  margin-top: 4rem;

  .getting-started-icon {
    img {
      margin-top: 1rem;
    }
  }

  .getting-started-icon-action {
    font-size: 14pt;
    padding: 0 20%;
    margin-top: 1rem;
  }
}