.storage-browser-filter {
  ul, li {
    list-style: none;
    padding-left: 7px;
  }

  ul {
    margin-bottom: .25rem;
  }

  .active {
    font-weight: 600;
  }

  .btn.storage-browser-radio-button {
    color: #000 !important;
  }
}