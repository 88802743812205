.notifications-list-header.card-header {
  background-color: #f0f0f0;
  border-top-left-radius: calc(-1px + 0.25rem);
  border-top-right-radius: calc(-1px + 0.25rem);
  margin:0;
  padding: 1rem 2rem;
}

.notifications-list-body.card-body {
  background-color: #fbfbfb;
  border-bottom-left-radius: calc(-1px + 0.25rem);
  border-bottom-right-radius: calc(-1px + 0.25rem);
}

.notifications-list-header {
  display:flex;
  justify-content: space-between;

  .badge {
    margin-left: 10px;
  }

  h1 {
    margin: 0;
  }
}

.notification-card {
  text-align: left;
  padding: 10px 0;
  background-color: #fff;
  border-radius: 4px;
  top: 0px;
  position: relative;
  margin-bottom: 15px;
  border: solid 1px #ccc;
}

.notification-card-content {
  padding-left: 20px;

  .notification-card-content-date {
    font-size: .8rem;
    font-weight: 800;
    color: #bbb;
  }
}

.notification-card-close {
  position: absolute;
  right: 10px;
  top: 0;
  width: 28px;
  cursor: pointer;
  height: 28px;
  font-size: 28px;
}