.storage-cell {
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    min-width: 34px;
    max-width: 34px;
    height: 30px;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    font-size: .6rem;
    font-weight: bold;
    line-height: 30px;
    color: #888;
    display:table-cell;
}

.storage-cell:hover {
    background-color: lightyellow;
}

.storage-cell.occupied {
    background-image: linear-gradient(45deg, #dddddd 14.29%, #eeeeee 14.29%, #eeeeee 50%, #dddddd 50%, #dddddd 64.29%, #eeeeee 64.29%, #eeeeee 100%);
    background-size: 9.90px 9.90px;
    color: #000;
}

.storage-cell.selected {
    background-image: none;
    background-size: auto;
    background-color: lightblue;
    color: #000;
}

.storage-cell.selected:hover {
    background-image: none;
    background-size: auto;
    background-color: lightcoral;
}

.storage-row {
    display:table-row;
}

.select-row {
    text-align: center;
}

.storage-table {
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    display: table;
    overflow-x: scroll;
}

.storage-location-notes-display {
    font-size: .8rem;
    color: #606060;
}

#storage-location-expand-button {
    text-align: left;
    margin-bottom: 1rem;

    svg {
        margin-left: 1rem;
    }
}