.dimScreen {
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1000;
}

.dimScreen video {
  margin-top: 52px;
  height: 80%;
  width: 100%;
}

.dimScreen .close-capture {
  text-align: center;
  background-color: #fff;
  padding: 0 1rem;
}

.capture-error {
  position: relative;
  height: 80%;
  width: 80%;
  z-index: 1001;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 15%
}