.bulk-update-container {
  border: solid 1px #ccc;
  padding: 1.5em;
  border-radius: .25em;
  background-color: #fafafa;
}

.bulk-update-actions {
  margin-left: 3em;
  margin-right: 3em;
  margin-top: 2em;
  text-align: center;
}