header #logo {
  padding-right: 20px;
  max-height: 20px;
}

header .logo {
  fill: #fff;
}

span.appName {
  color: #fff;
}

$nav-bgcolor: rgb(88, 39, 110);

header, header nav {
  height: 52px;
  margin-bottom: 1rem;
  background-color: $nav-bgcolor;
}

.notebook-link{
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-bottom: 3px;
}

.notebook-line {
  fill: #58276E;
}
.notebook {
  fill: #D4CCBF; 
}
.notebook:hover {
  fill: #fff;
}

.has-badge {
  position: relative;
}

.has-badge[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -2px;
  right: -2px;
  font-size: .6em;
  background: darkred;
  color: white;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  border-radius: 50%;
  box-shadow: 0 0 1px #333;
}

.navbar-dark .navbar-nav {
  .nav-link.nav-dropdown-link, .nav-link.nav-dropdown-link:hover {
    padding: 0;
    color: #000 !important;
  }

  .dropdown-menu-end {
    right:0;
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: #CCBFD4 !important;
}

.navbar-dark .navbar-nav .nav-link:hover {
color: #fff !important;
}

.active.nav-item .nav-link {
  color: #fff !important;
}

.navbar-dark .navbar-nav .dropdown-item.active {
  background-color: transparent;
}

.dropdown-item:active {
  background-color: transparent;
}

#nav-subscribe-now {
  //border: solid 1px darken($nav-bgcolor, 50%);
  border-radius: 3.2px;
  //background-color:  lighten($nav-bgcolor, 60%);
  //color: $nav-bgcolor !important;
  background-color: var(--la-orange);
  border: 1px solid var(--la-orange);
  color: var(--la-gray--dark) !important;
}

.nav-subscribe-now:hover {
  background-color: var(--la-orange);
}

#switch-inventory-views {
  display:flex;
  align-items: center;
  padding: 0 .5rem;

  button {
    flex-grow: 1;
    //font-size: .9rem;
    color: #CCBFD4;
  }

  div {
    border-bottom: .125rem solid transparent;
  }

  div.active {
    border-bottom: .125rem solid #fff;
    button {
      color: #fff !important;
    }
  }
}

#switch-inventory-views:before, #switch-inventory-views:after {
  display: block;
  content: '';
  border-left: 1px solid #CCBFD4;
  width: 1px;
  height: 50%;
  margin-right: .5rem;
}

#switch-inventory-views:after {
  margin-left: .5rem;
}

@media only screen and (max-width: 991px) {
  .nav-item {
    padding-left: 1rem
  }

  header, header nav {
    height: 52px;
  }

  nav.navbar {
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-brand {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .navbar-brand img {
    max-width: 200px;
  }

  .has-badge[data-badge]:after {
    right: auto;
  }

  .nav-item-subscribe-now {
    padding-left: 0;
  }

  .nav-subscribe-now {
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    width: auto;
    text-align: center;
    margin-bottom: 1rem;
  }
}