.subscription-expiration-notice {
  margin-top: -1rem;
  margin-bottom: 1rem;
  text-align:center;
  background-color: #fff4c2;
  line-height: 2rem;

  .subscription-expiration-date::before, .subscription-expiration-manage-link::before {
    content: " ";
  }
}