
.inventory-search-filter-head {
    display:flex;
    justify-content: space-between;

    button {
        padding:0 !important;
        margin:0 !important;
    }
}

.inventory-search-filter-head-close.btn {
    display:none;
}

.inventory-search-filter-head-title {
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
}

.inventory-filter-criteria-title {
    font-weight: 700;
    text-transform: uppercase;
    color: #606060;
}

.inventory-filter-criteria {
    margin-top: 1rem;

    legend {
        font-size: 1em;
        margin-bottom: 0;
    }
}

.DateRangePickerInput_arrow, .DateRangePickerInput_arrow_1 {
    display:none;
}

.DateRangePicker_picker {
    z-index: 3;
}

.inventory-search-filter {
    .inventory-type-color {
        margin-right: 0;
        display: inline-block;
    }
}