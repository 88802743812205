.inventory-tour-heading {
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.inventory-tour-pad-top {
  margin-top: 1rem;
}

.inventory-tour-pad-bottom {
  margin-bottom: 1rem;
}

.inventory-tour-link-color {
  color: #02738D;
}

.inventory-tour-pad-screenshot {
  border: solid 3px #000;
  text-align: center;
  img {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.inventory-tour-no-border {
  border:none;
}