.subscription-pricing-box {
  button {
    width: 100%;
  }

  .subscription-pricing-tab {
    text-align: center;
    display: flex;
    padding: 30px;
    border: solid 1px rgb(222, 226, 230);
    border-radius: 5px;
  }

  .subscription-pricing-tab-unboxed {
    padding: 0;
    border: none;
  }

  .subscription-pricing {
    width: 300px;
    margin-top: 15px;
    text-align: center;

    input[type="number"] {
      width: 80px;
      display: inline-block;
      margin-left: auto;
    }

    label {
      display: inline-block;
      width: 70px;
    }

    .subscription-pricing-details {
      border: solid 2px rgb(88, 39, 110);;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      padding: 15px 30px;
    }

    .subscription-pricing-term {
      display: block;
      font-size: 1.2rem;
      font-weight: 600;
      background-color: rgb(88, 39, 110);
      color: #fff;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      line-height: 3rem;
    }

    .subscription-pricing-price {
      font-size: 3rem;
      font-weight: 700;
      color: rgb(88, 39, 110);;
    }

    .subscription-pricing-price-dollar {
      color: rgb(88, 39, 110);
      position: relative;
      top: -1.2em;
      display: inline-block;
      padding-right: 3px;
      margin-left: -1em;
    }

    .subscription-pricing-per-user {
      position: relative;
      top: -0.8em;
      font-size: 80%;
      display: inline-block;
      padding-left: 3px;
    }

    .subscription-subscribe-now {
      margin-top: 30px;
    }

    .subscription-pricing-expected-cost {
      display: block;
      font-style: italic;
      color: #666;
    }

    .subscription-special-price-message {
      color: #666;
      display: block;
      font-weight: 600;
      font-size: .9em;
      margin-bottom: .5em;
    }
  }
}


.subscription-features {
  border-top: solid 1px rgb(222, 226, 230);
  padding-top: 1em;
  margin-top: 1em;
  text-align: left;

  ul {
    margin-left: -2em;
    list-style-type: none;
  }

  li {
    text-indent: -5px;
  }

  ul > li:before {
    content: "- ";
    text-indent: -5px;
  }
}