.inventory-import-modal .modal-footer {
    display: block;
    text-align: center;
}

.inventory-import-modal-parse-errors, .inventory-import-modal-parse-success {
    text-align: left;
    font-size:.9rem;
}

.inventory-import-modal-parse-error-retry {
    margin-top: 1rem;
}

.inventory-import-modal-parse-success-buttons {
    margin-top: 1rem;
    display:flex;
    justify-content: space-between;
}

.inventory-import-modal-download-template {
    margin-top: 1rem;
    text-align:center;
}