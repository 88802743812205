.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #aaa;
    z-index: 99;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.react-autosuggest__section-container {
    border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
    border-top: 0;
}

.react-autosuggest__section-title {
    padding: 10px 0 0 10px;
}
