.order-container-header {
    display:flex;
    justify-content: space-between;
}

.order-container-header-status-description {
    text-transform: uppercase;
    font-weight: 700;
}

.order-container-header-status .btn {
    margin-left: 20px;
}

.order-notes-history.row {
    border-top: solid 1px rgba(0,0,0,.125);
    background-color: #fdfdfd;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -1.25rem;
    padding-bottom: 1rem;
}

.order-notes-title {
    margin-right: 7px;
}

.order-view-inventory {
    padding-left: 5px;
}
.order-container-header-status-label {
    text-transform: uppercase;
    padding-right: 4px;
}

.order-total-container {
    border-radius:5px;
    border: solid 1px #ccc;
    margin-bottom: 1rem;
    background-color: #fafafa;
    padding: 1rem;
}