#inventory-search-advanced-search {
  position: absolute;
  z-index: 1049;
  background-color: #fff;
  width: 100%;
  padding: .5rem 1rem 1rem;
  border: solid 1px #ccc;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(60, 64, 67, 0.3), 0px 2px 6px 2px rgba(60, 64, 67, 0.15);

  .react-datepicker-wrapper, .react-datepicker__input-container {
    margin:0;
  }

  .react-datepicker-wrapper {
    display: inline-block;
  }

  .criteria-value-checkbox-label {
    margin-top: 3px;
    display: inline-block;
  }

  .react-datepicker__close-icon {
    padding-right: 8px;
  }
}

.advanced-search-modal-backdrop {
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1048;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}