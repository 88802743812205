.subscribe-total {
  display: flex;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  height: 100%;
}

.subscribe-description {
  text-align: center;
  color: #666;
}

.subscribe-pricing {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1rem;
}

.subscribe-pricing-option {
  padding: 1rem;
  text-align: center;
  background-color: #ebebeb;
  border-radius: 3px;
  cursor: pointer;
  border: solid 1px #ebebeb;
}

.subscribe-pricing-option.active {
  background-color: #fcfcfc;
}