#inventory-freezer-box {
  border-left: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-x: auto;
  margin-bottom: 1rem;
  font-size: .8em;

  .inventory-freezer-box-row:first-of-type .inventory-freezer-box-cell {
    border-top: 1px solid #ccc;
  }

  .inventory-freezer-box-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1;
    flex-basis: 100%;
    width: 100%;
  }

  .inventory-freezer-box-cell {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100px;
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    padding: 3px;
    overflow: hidden;
    color: #888;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
  }

  .inventory-freezer-box-cell:not(.no-add):focus, .inventory-freezer-box-cell:not(.no-add).active {
    background: lightyellow;
  }

  .inventory-freezer-box-cell.no-add {
    cursor: default;
  }

  .inventory-freezer-box-cell.empty {
    z-index: 2;
  }

  .inventory-freezer-box-cell.drag-over {
    background-color: #eafbff;

    path {
      color: #333;
    }
  }

  .inventory-freezer-box-cell-header {
    display: flex;
    justify-content: space-between;
    height: 17px;

    .inventory-type-color {
      height: 12px;
      width: 12px;
      border-radius: 6px;
      border-width: 0;
      border-color: transparent;
    }
  }

  .inventory-freezer-box-cell-label {
    color: #999;
  }

  .inventory-freezer-box-cell-name {
    text-align: center;
    text-overflow: clip;
    height: 36px;
    display: inline-block;
    overflow: hidden;
    width: 94px;
    position: relative;
    word-wrap: anywhere;
    line-height: 13px;
    font-size: 1em;
    color: #000;
    -webkit-mask-image: linear-gradient(180deg, #000, #000 26px, transparent);

    button {
      line-height: 13px;
      font-size: 1em;
    }
  }

  .inventory-freezer-box-cell-empty {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    padding-bottom: 17px;
    font-size: 1.5rem;

    path {
      color: #cccccc;
    }
  }

  .inventory-freezer-box-cell-type {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 94px;
  }
}


#inventory-freezer-box.moving, #inventory-freezer-box.copying, {
  .inventory-freezer-box-cell.occupied {
    background-image: linear-gradient(45deg, #dddddd 14.29%, #eeeeee 14.29%, #eeeeee 50%, #dddddd 50%, #dddddd 64.29%, #eeeeee 64.29%, #eeeeee 100%);
    background-size: 9.90px 9.90px;
    color: #000;
    cursor: default;
  }

  .inventory-freezer-box-cell.empty:hover {
    background-color: #fffff0;

    path {
      color: #333;
    }
  }
}

.inventory-freezer-box-col {
  flex-grow: 0;
  flex-shrink: 1;
}

.inventory-freezer-box-col.active {
  max-width: 66%;
}

#inventory-freezer-box-details {
  max-width: 260px;
  background-color: #F9F9F9;
  padding: .5rem;
  border: 1px solid #ccc;
  border-radius: .25rem;
  position: -webkit-sticky;
  position: sticky;
  top: 55px;
  align-self: flex-start;

  .inventory-type-color, .inventory-freezer-box-details-type {
    display: inline-block;
  }

  .inventory-type-color {
    margin-right: 5px;
  }

  .inventory-freezer-box-move-message, .inventory-freezer-box-copy-message {
    text-align: center;
    margin: 10px 0;
    padding: 5px;
    border-radius: 5px;
    background-color: white;
    border: solid 1px #f0f0f0;
  }

  .inventory-freezer-box-details-cell-label {
    font-weight: 600;
  }

  .inventory-freezer-box-details-item {
    font-size: .9rem;
    margin-top: 7px;
  }

  .inventory-freezer-box-details-header {
    text-transform: uppercase;
    color: #595959;
  }

  .inventory-freezer-box-details-value, .inventory-freezer-box-details-item-name, .inventory-freezer-box-details-type {
    word-wrap: break-word;
    word-break: break-all;
  }
}

.col.inventory-freezer-box-details-col {
  flex-grow: 2;
}