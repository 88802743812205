.item-attachment-upload-notes {
  font-size: .75rem;
  color: #666666;
  font-style: italic;
}

.item-attachment-upload-error {
  font-weight: 600;
  color: #000;
  border: solid #721c24 1px;
  width:100%;
  text-align:center;
  border-radius: 5px;
  background-color: lighten(#721c24, 50%);
  padding: 1rem;
}
