.superuser-account-section-title {
  font-size: 1.5rem;
}

.superuser-account-section {
  padding: 1rem 2rem;
  border: solid 1px #cccccc;
  border-radius: 1rem;
  margin-top: 1rem;
}

.superuser-account-section-title-edit {
  display: inline-block;
  margin-left: 1rem;
}

.superuser-account-common-detail {
  display: flex;
  justify-content: space-between;
}

.superuser-account-activity-history-item {
  border-radius: 5px;
  background-color: #ededed;
  padding: 5px;
  margin-bottom: 3px;
  font-size: .75rem;
}

.superuser-account-activity-history-item-head {
  display:flex;
  justify-content: space-between;
}

.superuser-account-activity-history-item-description {
  font-style: italic;
}

.superuser-account-activity-history-filter {
  display:flex;
  justify-content: space-between;
  margin-bottom: 3px;
}

.superuser-account-subscription-details {
  display:flex;
  justify-content: space-between;
}

.superuser-account-subscription-details-stat {
  background-color: #ebebeb;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 5px 15px;

  span {
    font-weight: 500;
    margin-top: 1rem;
    color: #666;
  }

  div {
    font-weight: 600;
  }
}