.manage-storage-location-edit-action {
    display: inline-block;
    padding-left: 5px;
}

.manage-storage-location-edit-action .btn-link {
    vertical-align: inherit;
}

.manage-storage-location-duplicate-action {
    display: inline-block;
    padding-left: 5px;
}

.manage-storage-location-duplicate-action .btn-link {
    vertical-align: inherit;
}

.manage-storage-location ul {
    list-style: none;
    padding-left: 14px;
}

.manage-storage-location li {
    padding-left: 6px;
}

.manage-storage-location-child-grid {
    display: inline-block;
    margin-left: 5px;
    font-size: .8rem;
}

.manage-storage-location-child:hover .manage-storage-location-edit {
    display: inline-block;
}

.manage-storage-location-edit-container {
    border: solid 1px #d8dfe5;
    background-color: #f0f8ff;
    padding: 10px;
    border-radius: .25rem;
}

.manage-storage-location-edit-container label {
    font-size: .8rem;
    font-weight: 600;
}

.manage-storage-location-edit-container .form-group {
    margin-bottom:0;
}

.manage-storage-location-new-child-container {
    margin-left: 10px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.manage-storage-location-add-child-action.btn-link, .manage-storage-location-add-child-action.btn-link:hover {
    font-size: .8rem;
}

.manage-storage-location-grid {
    margin-left: .25rem;
}
