.manage-inventory-type-attribute-column-label {
  max-width: 350px;
}

.manage-inventory-type-attribute-column-type {
  width: 180px;
}

.manage-inventory-type-attribute-column-options {
  width: 34%;
}

.manage-inventory-type-attribute-column-required {
  width: 100px;
}

.manage-inventory-type-attribute-column-actions {
  width: 200px;
}

.manage-inventory-type-attribute-column-actions button:not(:first-child) {
  margin-left: 10px;
}

.manage-inventory-attributes-header-container div {
  display: inline-block;
}

.manage-inventory-attributes-header-container div:not(:first-child) {
  margin-left: 5px;
}

.inventory-type-row-small-col {
  width: 80px;
  text-align: center;
}

.inventory-type-row-action-col {
  width: 120px;
}

.inventory-type-color {
  width: 20px;
  height: 20px;
  border: solid 1px #999999;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
}

.inventory-type-color-none:after {
  content: "";
  position: absolute;
  border-top: 1px solid red;
  width: 18px;
  transform: rotate(45deg);
  transform-origin: 0% 0%;
  margin-left: -8px;
  margin-top: 2px;
}

#inventory-type-color-modal {
  .inventory-type-color {
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }

  .inventory-type-color-none:after {
    margin-left: 1px;
    margin-top: 3px;
    width: 28px;
  }

  input[type="color"] {
    opacity: 0;
    width: 1px;
    height: 1px;
    margin-left: -26px;
  }

  .inventory-type-color-custom {
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;

    svg {
      font-size: 1.75rem;
    }
  }
}

.inventory-type-color-selector {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  border: solid #000 1px;

  width: 260px;
  left: 50%;
  margin-top: 2px;
  margin-left: -130px;
}

.standard-field-required-col {
  display:flex;
  justify-content: center;
}

.standard-field-visible-col {
  padding-left: 1em;
}
//
//.inventory-type-color-selector:before{
//  content: "";
//  position: absolute;
//  margin-top: -20px;
//  left:110px;
//  z-index: 1;
//  border: solid 15px transparent;
//  border-bottom-color: #FFF;
//}