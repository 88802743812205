.manage-role-permission-group-title {
  font-weight: 600;
  text-transform: uppercase;
}

.manage-role-permission-group {
  padding-bottom: 1rem;
}

.manage-role-permission-details {
  margin-left: 1rem;
}