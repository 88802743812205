.add-subscription-users-total {
  display: flex;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  height:100%;
}

.add-subscription-users-description {
  text-align: center;
  color: #666;
}