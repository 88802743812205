
.table-to-card-force-break {
  display: none;
}

.table-to-card-label {
display:none;
}

@media only screen and (max-width: 768px) {
  .table-to-card {
    table, thead, tbody, th, td, tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
      margin-bottom: 1rem;
      border-radius: .25rem;
    }

    td {
      border: none;
      display: flex;
    }

    tbody tr:nth-of-type(2n+1) td:not(:last-child) {
      border-bottom: solid 1px #ddd;
    }

    tbody tr:nth-of-type(2n) td {
      border-bottom: solid 1px #eee;
    }

    .table-to-card-label {
      display:inline;
      flex:50%;
      font-weight: 600;
      white-space: nowrap;
      flex-wrap: nowrap;
    }

    .table-to-card-content {
      flex:50%;
      flex-wrap:wrap;
      //max-width: 100%;
      white-space: normal;
      overflow: visible;
      text-overflow: initial;
    }

    .table-to-card-force-break {
      flex-basis: 100%;
      height: 0;
      display: block;
    }
  }
}

.responsive-search-filter-head-close.btn  {
  display:none;
}

@media only screen and (max-width: 1199px) {
  .responsive-search-filter {
    width: 0;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    bottom:0;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 60px;
    transition: 0.5s;
    background-color: #ffffff;
  }

  .filter-shown {
    width:90%;
    padding-left: 2rem;
    padding-right: 1rem;
    box-shadow: 3px 3px 5px #ccc;
  }

  .responsive-search-filter-head-close.btn  {
    display:inline-block;
  }

  .responsive-search-filter-head-close.btn.btn-link {
    color: #000 !important;
  }
}

@media only screen and (max-width: 768px) {
  .sort-results-mobile {
    display: flex;
    padding: 1rem 0;

    label {
      margin-right: 1rem;
      width: 40%
    }
  }

  .table-to-card {
    .table-to-card-force-break {
      flex-basis: 100%;
      height: 0;
      display: block;
    }
  }
}
