.inventory-search-result-order-reorder, .inventory-search-result-order-dates {
    font-size: 1rem;
}

.inventory-search-result-order-date-detail {
    display:flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (min-width: 992px) {
    .inventory-search-result-unit {
        margin-left: 3px;
    }

    .inventory-search-result-name {
        max-width: 350px;
    }

    .inventory-search-result-location {
        max-width: 350px;
    }

    .inventory-search-result-type {
        min-width: 160px;
        max-width: 200px;
    }

    .inventory-search-result-price {
        width: 100px;
    }

    .inventory-search-result-quantity {
        width: 120px;
    }

    .inventory-search-result-received-date {
        min-width: 130px;
    }

    .inventory-search-result-order-reorder, .inventory-search-result-use-now {
        width: 110px;
        text-align: right;
    }
}

#inventory-search-result-container {
    .use-now-button:focus {
        box-shadow: none;
    }
}