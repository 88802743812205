.superuser-stats {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 1rem;
}

.superuser-stats:not(:first-of-type) {
  margin-top: 1rem;
}

.superuser-stat {
  background-color: #ebebeb;
  width: 300px;
  text-align: center;
  border-radius: 1rem;

  .superuser-stat-heading {
    font-weight: 500;
    font-size: 1.5rem;
    margin-top: 1rem;
    color: #666;
  }

  .superuser-stat-number {
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}

.superuser-find-user {
  border-radius: 1rem;
  border: solid 1px #ccc;
  padding: 1rem 2rem;
  background-color: #fafafa;
}

.superuser-find-header {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 1.2rem;
}

.superuser-find-user-search {
  display:flex;
}

#super-user-user-details {
  .user-details-header {
    display:flex;
    justify-content: space-between;
  }
}