.selected-columns, .available-columns {
  min-height: 50px;
  border: solid 1px #ccc;
  display:flex;
  align-items: center;
  padding: .5rem;
  border-radius: 5px;
}

.available-columns {
  margin-bottom: 1rem;
}

.table-column {
  width: 100%
}

.table-column-item {
  border-radius: 5px;
  border: solid 1px #02738D;
  background-color: #A1CCD7;
  padding: 5px;
  margin: 0 2px;
  line-height: 30px;
  color: #000000;

  button {
    margin-left: 10px;
    margin-top: 0;
    margin-bottom: 0;
    color: #000000;
  }
}

.display-settings-preview {
  position:relative;

  .display-settings-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    color: #666;
    text-align: center;
    font-size: 3rem;
    display:flex;
    align-items:flex-end;
    justify-content: center;

    div {
      margin-top: auto;
    }
  }
}

