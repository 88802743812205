.move-item-destination, .move-item-source, .move-item-new-parent, .move-item-disabled  {
    display: inline-block;
    padding: 3px 6px;
    border-radius: 4px;
}

.move-item-destination {
    cursor: pointer;
}

.move-item-new-parent {
    background-color: lightyellow;
    border: solid 1px #b9b97e;
}

.move-item-destination:hover {
    background-color: lightyellow;
}

.move-item-source {
    background-color: aliceblue;
}

.modal-footer-with-error {
    justify-content: space-between !important;
}

.move-storage-cancel-button {
    margin-left: .5rem;
}