.subscription-management-actions {
  display:flex;
  justify-content: space-evenly;
  border-top: 1px solid rgba(0,0,0,.125);
  margin-top: .75rem;
  padding-top: .75rem;
}

.subscription-dates {
  margin-top: 1rem;
}

.subscription-product-name {
  font-weight: 700;
}