.notes {
    font-style: italic;
    color:#999;
}

.recently-used {
    padding-top:20px;
}

.home-lab-name {
    font-size: 1.2rem;
    margin: 1rem 0;
    color: #555;
}
