.management-nav ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

$border: solid 1px #ccc;

@media only screen and (min-width: 600px) and (max-width: 1023px) {
  .management-nav ol {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .management-nav li {
    text-align: center;
    align-items: center;
  }

  .management-nav {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .management-container-contents {overflow: auto;}
}

.management-container {
  border: solid 1px #ccc;
  border-radius: .25rem;
  overflow: hidden;
}

.management-container.row {
  margin: 0 1rem;
}

.management-container-list {
  background-color: #fafafa;
  padding: 20px 15px !important;
  border-right: $border;
  @media only screen and (max-width: 768px) {
    border-right: none;
    border-bottom: $border;
  }
}

.management-container-list-item {
  margin-bottom: 2px;
  width: 100%;
  text-align: left;
  border-radius: .25rem;
  color: #4e555b;
  padding: 5px 8px !important;

  a, a:hover, a:visited, a:active {
    color: #000;
    text-decoration: none;
    display: block;
  }

  .btn, .btn:hover {
    width:100%;
    text-align:left;
    text-decoration: none;
  }
}

.management-container-list-item:hover, .management-container-list-item:active, .management-container-list-item.active {
  background-color: #f0f0f0;
  text-decoration: none;
  color: #000;
}

.management-container-list-item-new {
  text-align: center;
  margin-bottom: 10px;
}

.management-container-contents {
  padding: 20px;

  .table th {
    border-top: none;
  }
}

.management-edit-name-container, .management-name-container {
  display: flex;
  border-bottom: solid 1px #ccc;
  margin-bottom: 25px;
  align-items: flex-start;
  margin-top: .5rem;
}

.management-edit-name.form-group, .management-item-name {
  flex: 1;
  width: 100%;
  margin-bottom: .5rem;
}

.management-item-name {
  font-size: 1.2rem;
  font-weight: 600;
  .form-group {
    margin: 0;
    font-weight: normal;
  }
}

.management-edit-name-container button, .management-name-container button {
  margin-left: 10px;
  margin-bottom: .5rem;
}

@media only screen and (max-width: 600px) {
  .management-container-list {
    border-right: none;
    border-bottom: $border;
    padding: 10px;
  }
}

.management-header {
  display: flex;
  justify-content: flex-start;
  border-bottom: $border;
  margin-bottom: 1rem;
  .inventory-heading {
    margin-bottom: 0;
  }

  .badge {
    margin-left: 10px;
  }

  .management-header-action {
    margin-left: auto;
  }

  .nav-pills {
    .nav-link {
      padding-top: .3rem;
      padding-bottom: .3rem;
      border: solid 1px transparent;
    }
    .nav-link:active, .nav-link.active {
      background-color: #fff;
      color: #666;
      border: solid 1px #666;
    }
  }
}

.management-edit-footer {
  text-align: right;
}

td.management-item-delete {
  text-align: right;
}

.management-panel {
  padding: 1rem;
  border: solid 1px #ddd;
  margin-bottom: 1rem;
  border-radius: 5px;
  background-color: #fafafa;
}
