.order-calculation-line,
.order-total-line {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: right;
}

.order-calculation-times {
  font-size: 1.3rem;
  vertical-align: middle;
  color: #606060;
  line-height: 1.3rem;
  margin: 0 8px;
}

.order-total-line {
  text-align: right;
}

.order-total-line hr {
  background-color: #606060;
  border: 1px solid #606060;
  border-radius: 20px;
  height: 2px;
}

.order-request-note-text {
//   color: #606060;
//   font-style: italic;
//   font-size: 1.2rem;
//   padding: 10px 0;
//   margin: 10px 0;
//   border-radius: 5px;

  div {
    display: inline;
  }

  div:not(:first-child) {
    padding-left: 12px;
  }

  div:not(:first-child)::before {
    padding-right: 12px;

    content: "|";
  }
}
