footer {
  position: fixed;
  bottom: 0;
  text-align: center;
  width: 100%;
  font-size: 0.8em;
  background-color: #fff;
}

body {
  padding-bottom: 28px;
}

a.skip-main {
  left:-999px;
  position:absolute;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  z-index:-999;
}
a.skip-main:focus, a.skip-main:active {
  color: #fff;
  background-color:#000;
  left: auto;
  top: 100px;
  width: 30%;
  height: auto;
  overflow:auto;
  margin: 10px 35%;
  padding:5px;
  border-radius: 15px;
  border:4px solid yellow;
  text-align:center;
  font-size:1.2em;
  z-index:999;
}

.container {
  padding: 10px 26px;
  display: flex;
  flex-grow: 1;
}

.hidden {
  display: none;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.display-flex {
  display: flex;
}

.display-block {
  display: block;
}

.full-width {
  width: 100%;
}

.padding-top-10 {
  padding-top: 10px;
}

.btn-link {
  padding: 0 !important;
  vertical-align: inherit !important;
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
}

.getting-started-icon-action a {
  text-decoration: underline !important;
}

.btn-link:hover, a:hover {
  text-decoration: underline !important;
}

.nav-link, .nav-link:hover, .navbar-brand, .navbar-brand:hover {
  text-decoration: none !important;
}

.label-secondary {
  font-size: 0.7rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #606060;
}

.label-select-all {
  font-size: 0.7rem;
  color: #606060;
  padding-bottom: 2px;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.row.no-margins {
  margin: 0;
}

.form-is-invalid {
  display: inline-block;
  color: #dc3545;
  margin-right: 30px;
}

.error-note {
  color: #dc3545;
  font-size: .8rem;
}

.button-icon {
  margin-right: 5px;
}

$faux-height: 60px;

.faux-modal-header {
  position: fixed;
  left: 0;
  top: 52px;
  width: 100%;
  height: $faux-height;
  line-height: $faux-height;
  background-color: rgb(248, 248, 248);
  border-bottom: solid 1px #e7e7e7;
  padding: 0 1rem;
  z-index: 2;

  .faux-modal-close {
    float: right;

    .btn.btn-secondary {
      background-color: transparent !important;
      border: none;
      color: #666 !important;
      font-size: 2rem;
      font-weight: 800;
    }

    .btn.btn-secondary:hover {
      color: #999 !important;
      background-color: transparent !important;
    }

    .btn.btn-secondary:active {
      background-color: transparent !important;
    }
  }

  .faux-modal-title {
    font-size: 1.285714em;
    color: rgb(51, 51, 51);
    float: left;
  }

  h1 {
    font-size: 1.285714rem;
    line-height: 60px;
    margin: 0;
  }
}

.faux-modal-body {
  left: 0;
  margin-top: $faux-height;
  width: 100%;
  padding-bottom: $faux-height + 15px;
  padding-top: 1rem;
}

.faux-modal-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 1rem;
  background-color: rgb(248, 248, 248);
  border-top: solid 1px #e7e7e7;
  color: #333;
  line-height: $faux-height;
  height: $faux-height;
  text-align: right;

  a, .btn.btn-link {
    color: #02738D !important;
  }

  .float-left, .float-right {
    line-height: $faux-height;
  }

  .custom-checkbox .custom-control-label::before {
    margin-top: 18px;
  }

  .btn-link,
  .btn-link:hover,
  .btn-link:active {
    color: #fff;
  }

  .faux-modal-footer-checkbox svg {
    position:relative;
    top:-4px;
  }
}

textarea {
  min-height: 4rem;
}

.required-field-indicator {
  color: red;
  margin-bottom: 5px;
  height: 8px;
  font-size: small;
}

.inventory-heading {
  font-size: 1.285714em;
  color: rgb(51, 51, 51);
}

.page-item .page-link {
  color: #333;
}

//
//.btn.btn-secondary, .page-item.active .page-link {
//  color: #333 !important;
//  background-color: #FCB10B !important;
//  border-color: #FCB10B !important;
//  font-weight: 500 !important;
//}

a:not(.nav-link):not(.btn-outline-secondary), .btn.btn-link {
  color: #02738D !important;
}

.popover-body {
  a.alert-link {
    color: #062c33 !important;
  }
}

.horizontal-scrollable-modal {
  overflow-x: auto;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #8C8C8C;
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;
}

.dropzone:hover {
  border-color: #bdbdbd;
  color: #545454;
}

.dropzone img {
  filter: invert(47%) sepia(99%) saturate(6%) hue-rotate(321deg) brightness(96%) contrast(99%);
}

img.link {
  height: 1em;
  width: 1em;
  //font-size: 16px;
  //height: 16px;
  //margin: 0 5px;
  filter: invert(31%) sepia(87%) saturate(807%) hue-rotate(153deg) brightness(93%) contrast(102%);
}

table th {
  white-space: nowrap;
}

.jkLfWi.form-check-input:disabled {
  opacity: 0;
  pointer-events: none;
}


button.btn.nav-link {
  background: none;
  border: 0;

  &:hover, &:active, &:focus, &:focus-visible {
    background: none;
    border: 0;
    margin: 0;
  }
  &:focus, &:focus-visible {
    outline: solid 1px #fff;
    box-shadow: none;
  }
}

@media (prefers-contrast: more) {
  .dropdown-toggle {
    &::after {
      width: .75em;
      height: .4em;
      margin-left: .25em;
      border: 0;
      vertical-align: middle;
      background-color: currentColor;
      transform: none;
      clip-path: polygon(100% 0, 0 0, 50% 100%);
    }
  }
}

.session-expired-card {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 64px 16px;
  width: 100%;
  max-width: 424px;
  min-height: 480px;
  background: white;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

  .session-expired-card-body {
    display: grid;

    .session-expired-button {
      margin-top: 100%;
    }

    a {
      width: 100%;
    }
  }
}

a.btn-orange, a.btn-orange:visited, a.btn-orange:hover {
  color: #333 !important;
  background: #FCB10B !important;
  border-color: #FCB10B !important;
  font-weight: 500;
  text-decoration: none !important;
}

@import "../components/ResponsiveSearch/ResponsiveSearch";