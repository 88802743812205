.user-notice {
  text-align: center;
  background-color: #fff4c2;
  line-height: 30px;
  margin: -20px -15px 1rem;
  min-height: 30px;
  display: flex;

  div {
    margin-left: auto;
  }

  .user-notice-close {
    justify-content: end;
    margin-right: 2rem;

    .btn, .btn-link {
      font-size: 1rem;
      font-weight: 600;
      color: #58543b;
    }
  }
}