.admin-search-results-column-lab-details-label, .admin-search-results-column-lab-details-value {
  display:inline-block;
  font-size: .8rem;
  color: #606060;
}

.admin-search-results-column-lab-details-label {
  font-weight: 700;
}

.admin-search-results-column-lab-details-value {
  padding: 0 5px;
}

.search-result-lab-name {
  overflow: visible;
  white-space: normal;
  max-width: 100%;
}

.admin-search-results-column-lab-header {
  width: 50%;
}