
ul.tree {
  padding:0;

  list-style: none;

  ul {
    margin: 0;
    padding:0;
    padding-left: 20px;
  }

  li {
    list-style: none;
  }

  .arrow {
    display: inline-block;
    width: 20px;
  }


  .checkbox-tree-label {
    margin-left: 3px;
    cursor: pointer;
  }
}