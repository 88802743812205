.order-search-filter-head {
    display:flex;
    justify-content: space-between;
}

.order-search-filter-head-title {
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
}

.order-filter-criteria-title {
    font-weight: 700;
    text-transform: uppercase;
    color: #606060;
}

.order-filter-criteria label {
    margin-bottom:0;
}

.order-filter-criteria label span {
    padding: 0 5px;
}

.order-search-filter {
    .inventory-type-color {
        margin-right: 0;
        display: inline-block;
    }
}