.order-modal-notes-content {
    font-style: italic;
}

.order-modal-important {
    font-weight: 600;
}

/*.order-modal-cancel {*/
/*    margin-right: 20px;*/
/*}*/

.order-modal-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: .5rem;
    display:block;
}

.order-container-header-status-label {
    text-transform: uppercase;
    padding-right: 4px;
}