.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #8C8C8C;
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer;
}

.dropzone:hover {
    border-color: #bdbdbd;
    color: #545454;
}

.item-attachments-row {
    margin-bottom: 1rem;
}