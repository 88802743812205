.reports-nav ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

$border: solid 1px #ccc;

@media only screen and (min-width: 600px) and (max-width: 1023px) {
  .reports-nav ol {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .reports-nav li {
    text-align: center;
    align-items: center;
  }

  .reports-nav {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .reports-container-contents {overflow: auto;}
}

.reports-container {
  border: solid 1px #ccc;
  border-radius: .25rem;
  overflow: hidden;

  .reports-contents {
    padding: 1rem;

    h1 {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
  }

  .reports-header {
    font-size: 1.5em;
    border-bottom: solid 1px #ccc;
  }
}



.reports-container.row {
  margin: 0 1rem;
}

.reports-container-list {
  background-color: #fafafa;
  padding: 20px 15px !important;
  border-right: $border;
  @media only screen and (max-width: 768px) {
    border-right: none;
    border-bottom: $border;
  }
}

.reports-container-list-item {
  margin-bottom: 2px;
  width: 100%;
  text-align: left;
  border-radius: .25rem;
  color: #4e555b;
  padding: 5px 8px !important;

  a, a:hover, a:visited, a:active {
    color: #000;
    text-decoration: none;
    display: block;
  }

  .btn, .btn:hover {
    width:100%;
    text-align:left;
    text-decoration: none;
  }
}

.reports-container-list-item:hover, .reports-container-list-item:active, .reports-container-list-item.active {
  background-color: #f0f0f0;
  text-decoration: none;
  color: #000;
}

.reports-container-list-item-new {
  text-align: center;
  margin-bottom: 10px;
}

.reports-container-contents {
  padding: 20px;

  .table th {
    border-top: none;
  }
}

@media only screen and (max-width: 600px) {
  .reports-container-list {
    border-right: none;
    border-bottom: $border;
    padding: 10px;
  }
}
