.order-search-result-price-total {
    display: block;
}

.order-search-result-details, .order-search-result-status-date, .order-search-result-info-item, .order-search-result-requested-by, .order-search-action-notes-from, .order-search-action-notes-content {
    display: block;
    font-size: .8rem;
    color: #606060;
}

.order-search-result-info-item-label {
    font-weight: 700;
    display: block;
}

table td.order-search-result-action {
    text-align: center;
    vertical-align: middle;
}

.order-search-result-action button {
    margin-bottom: 6px;
}

#order-search-result-container {
    margin-top:15px;
    background-color: #fff;
    padding: 0 10px;
    border-radius: 4px;
    margin-bottom: 4px;

    .inventory-type-color {
        margin-right: .5rem;
        display: inline-block;
    }
}

#order-search-result-container .table th {
    border-top: none;
}

#order-search-results-no-results {
    text-align: center;
    padding: 2rem;
}

@media only screen and (max-width: 1199px)  {
    .order-search-result-info-item-label {
        display: inline;
    }
    .order-search-result-info-item-label:after {
        content: (" ")
    }

    .order-search-result-action {
        display: flex;
        justify-content: center;
    }

    .order-search-result-action button {
        margin-bottom: 0;
        margin-right: 1rem;
    }
    .order-search-result-action a {
        margin-top: .25rem;
    }
}