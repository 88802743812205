.manage-users-change-role.btn-link, .manage-users-change-role.btn-link:hover {
    color: #606060;
    margin-left: 4px;
}

.manage-users-list-role .form-group {
    margin-bottom: 0;
}

.manage-users-list-container .badge {
    margin-left: 6px;
}