#inventory-search-text-container {
  display: flex;

}

#inventory-search-text-container.with-account-name {
  padding-top: 8px;
}

#inventory-search-text-container .rbt {
  width: 100%;
}

#inventory-search-text-container .inventory-search-text-input {
  flex: 1;
  margin-right: 5px;
}

.inventory-search-text-input {
  width: 100%;
  position: relative;

  .inventory-search-text-clear {
    position:absolute;
    right: 0;
    top: 0;
    height: 100%;
    border: none;
    background: transparent;
    padding-right: 10px;
  }
}

#inventory-search-text-container form {
  display:flex;
  flex-grow: 1;
}

#inventory-search-text-container .form-control {
  flex: 1;
  margin-right: 5px;
}

.inventory-search-result-location-cells, #inventory-search-result-container .storage-location-cells {
  display: block;
  text-overflow: ellipsis;
  font-size: .8rem;
}

#inventory-search-result-container th .btn-link {
  color: rgb(33, 37, 41);
  font-weight: 700;
}

#inventory-search-result-container .page-item.active .page-link {
  z-index: 0;
}

.inventory-search-actions .btn-link {
  float: left;
  margin-right: 10px;
}

.inventory-search-header {
  display: flex;
  justify-content: space-between;
}

.inventory-search-primary-content.card {
  background-color: #fafafa;
  margin-bottom: 1.25rem;
}

.inventory-search-primary-content .card-body {
  padding-bottom: 0;
}

.sort-results-mobile {
  display: none;
}


//@media only screen and (max-width: 768px) {
//  #inventory-search-advanced-search {
//    left: 0;
//    width: 100%;
//  }
//}

.inventory-search-advanced-search-row {
  border: solid 1px #ccc;
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 5px;
  display:flex;
  flex-wrap: wrap;

  .inventory-search-advanced-search-row-criteria {
    display:flex;
    justify-content: left;
    flex-grow: 1;
    flex-basis: 0px;

    div {
      margin: 3px;
    }
  }

  .range-option {
    width: auto;
    display: inline-block;
  }

  .criteria-value {
    flex-grow: 1;
  }

  //.remove-criteria {
  //  margin-top: -5px;
  //  float:right;
  //}

  select {
    width: auto;
  }

  .duplicated-note, .excluded-note {
    width:100%;
    font-size: .7rem;
    line-height: .7rem;
  }
}

.inventory-search-advanced-search-row.empty {
  border: dashed 1px #ccc;
}

.inventory-search-advanced-search-row.duplicated {
  background-color: #ffffe0;
}

.inventory-search-advanced-search-row.excluded {
  background-color: #f6d6d9;
}

.advanced-search-footer{
  display:flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px #f0f0f0 solid;
  padding-top: 1rem;
  margin-top: 1rem;

  .saved-search-name.btn.btn-link {
    color: #323234 !important;
  }
}
.advanced-search-footer-left {
  text-align:left;
  display:flex;
}

.advanced-search-footer-right {
  text-align:right;
  display:flex;
  justify-content: space-between;
}

//.advanced-search-footer-center {
//  display:flex;
//}

.advanced-search-saved-search-list {
  position: absolute;
  background-color: white;
  width: auto;
  max-width:100%;
  padding: 1rem;
  z-index:2;
  border: solid 1px #ccc;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(60, 64, 67, 0.3), 0px 2px 6px 2px rgba(60, 64, 67, 0.15);

  ul {
    list-style: none;
    margin:0;
    padding:0;
  }

  li {
    display: flex;
    justify-content: space-between;
  }

  .btn {
    //font-size: .9rem;
  }
}

.inventory-search-advanced-search-criteria-row {
  display:flex;
  justify-content: left;
  div {
    padding-right: 10px;
  }
}

.saved-search-update-note {
  font-size: .9rem;
  font-style: italic;
}

.inventory-search-lab-name {
  font-size: .9rem;
  color: #555;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -10px;
  padding-top: 2px;
  padding-bottom: 5px;
}

@media only screen and (max-width: 1056px) {
  .inventory-search-advanced-search-row-criteria {
    flex-wrap:wrap;
  }

  .advanced-search-footer {
    flex-wrap: wrap;
  }
  .advanced-search-footer-right {
    flex-grow: 1;
    margin-top: 1rem;
  }

  .advanced-search-saved-search-list {
    left:0px;
    width:100%;
  }
}