.update-icons {
  padding-left: 5px;
}

.update-icons-row {
  padding-left: 0;
  margin-left: -5px;
  padding-top: 5px;
}

.update-icons a {
  padding: 0 5px;
}

.label {
  font-weight: bold;
}

.history-item {
  font-size: .9rem;
}

.history-item span + span {
  padding-left: 4px;
}

.history-item-user {
  font-weight: bold;
}

.action-row {
  div {
    display: inline-block
  }

  float: right;
}

#inventory-body {
  .tab-pane {
    margin-top: 15px;
  }
}

#inventory-item-display-container {
  .faux-modal-title {
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .nav-tabs {
    cursor: default;
  }

  .inventory-type-color {
    display: inline-block;
    margin-right: .5rem;
  }

  .inventory-type-with-color {
    line-height: 20px;
  }

  .inventory-link-item {
    span {
      display: inline-block;
      margin-right: 10px;
    }
  }
}

#show-storage-update-trigger {
  text-align: left;
}

.chemical-safety-image {
  margin-right: 1rem;
  text-align: center;
  display: inline-block;

  span {
    display: block;
    font-size: .7rem;
  }
}

@media only screen and (max-width: 768px) {

  input.quantity {
    width: 75px;
  }

  .action-row {
    display: block;
    clear: both;
    background-color: #fff;
    width: 100%;
    text-align: right;
  }

  #inventory-item-display-container {
    .faux-modal-body {
      margin-top: 100px !important;
    }
  }
}