.location-management-floor, .location-management-room, .location-management-no-floor, {
  margin-left: 2rem;
}

.location-management-building {
  margin-bottom: 1rem;
  padding-bottom: .25rem;
  border-bottom: solid 1px #ebebeb;

  .btn.btn-link.expand-caret {
    color: black !important;
  }
}

.manage-site-locations-edit-container {
  border: 1px solid #d8dfe5;
  background-color: #f0f8ff;
  padding: 10px;
  border-radius: .25rem;
}

.manage-site-locations-buttons {
  display: flex;
  justify-content: space-between;
}

.room-list-no-floor {
  font-size: .8rem;
  text-transform: uppercase;
  color: #6c757d;
}

@media all and (min-width: 576px) and (max-width: 767px) {
  .btn-sm-block {
    width: 100% !important;
    display: block !important;
  }
}